<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="novo usuário" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form  class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="user.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sobrenome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="user.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CPF </label>
                                                <input type="text" @blur="verifyDataUser('cpf')" class="form-control" placeholder="000.000.000-00" v-model="user.cpf" v-validate="'required'" name="cpf" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'">
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Função</label>
                                                <b-form-select class="custom-select mr-sm-2" :options="['Tudo','Arquivos', 'Arquivos e portais', 'Portais', 'Empreendimentos', 'Empreendimentos e arquivos',  'Empreendimentos e portais']" v-model="user.function">
                                                </b-form-select>
                                            </div>
                                        </div> -->
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>E-mail </label>
                                                <input type="email" @blur="verifyDataUser('email')" class="form-control" placeholder="abc@example.com" v-model="user.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Whatsapp </label>
                                                <input type="text" class="form-control" placeholder="(99) 9 9999-9999" v-model="user.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="user.password" ref="password" v-validate="passwordValidate" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirmed" :class="{'is-invalid': errors.has('confirmed')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <div class="form-group">
                                                <label>Escolha a permissão</label></br>
                                                <select
                                                class="form-control"
                                                id="radio-group"                                               
                                                v-model="permissoes"                                             
                                                name="functions"
                                                >
                                                <option v-for="permissao in permissionsgroupolista" :selected="permissoes == user['permissions_group']['id']" name="permissao" :value="permissao['value']">{{permissao["text"]}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn ml-2 btn-info">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import AdminService from "@/services/resources/AdminService";
import TitleService from "@/services/resources/TitleService";
import AccessPermissionService from "@/services/resources/AccessPermissionService";
import ConsultDataUserService from "@/services/resources/ConsultDataUser";
const consultService = ConsultDataUserService.build();
import AccessPermissionGroupService from "@/services/resources/AccessPermissionGroupService";
const service2 = AccessPermissionGroupService.build();
import AttachWithPermissionsGroupService from "@/services/resources/AttachWithPermissionsGroupService";
const service3 = AttachWithPermissionsGroupService.build();
import DetachWithPermissionsGroupService from "@/services/resources/DetachWithPermissionsGroupService";
const service4 = DetachWithPermissionsGroupService.build();
const servicePermission = AccessPermissionService.build();
const serviceTitle = TitleService.build();
const service = AdminService.build();

export default {
   data(){
       return{

           user:{
               first_name: null,
               last_name: null,
               cpf: null,
               function: [],
              
               title_id: null,
               email: null,
            
               cellphone: null,
               password: null,
               permissions: []
           },
           permissionsgroupolista:[],
           permissoes:[],
           titles: [ { value: null, text: 'Selecione' } ],
           permissions: [],
           pagination: {
                  currentPage: 1,
                 perPage: 26,
               }    ,
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'novo usuário',
                    to: 'TimeUserNew'
                }
            ],
            menu:[
                {
                    name: 'Usuários',
                    to: 'TimeUser'
                },
                {
                    name: 'Novo usuário',
                    to: 'TimeUserNew'
                }
            ]
       }
   },
   computed:{
       passwordValidate(){
           return this.id ? 'min:8' : 'required|min:8';
       },
       confirmPasswordValidate(){
           return this.id ? 'confirmed:password' : 'required|confirmed:password';
       }
   },
   methods:{
    verifyDataUser(type){

            let data     = {};
            data['role_id'] = 4;//ADMIN
            
            if(type === 'cpf') {
  
                data['cpf'] = this.user.cpf;
      
                consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('Cpf já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            } else {

                    data['email'] = this.user.email;
            
                     consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('E-mail já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            }

       },
       save(){
        this.saveUserGroupPermission()
        
        this.$validator.validateAll().then((result) => {
            if (result) {
                let user = Object.assign(this.user)
                if(this.id){
                    user.id = this.id;

                    service
                    .update(user)
                    .then(resp => {
                    this.$bvToast.toast('Seu usuário foi atualizado com sucesso!', {
                        title: 'Usuário atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do usuário',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }else{
                    service
                    .create(user)
                    .then(resp => {
                    this.$bvToast.toast('Seu usuário foi criado com sucesso!', {
                        title: 'Usuário criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do usuário',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }

          
            }
        });
       },
       fetchGroupPermissions(){

let data = {
    page: this.pagination.currentPage,
    per_page: this.pagination.perPage
}

if(this.search){
    data['search'] = this.search;
}

service2
    .search(data)
    .then(re => {
        let permissoes  = []
    

        re["data"].forEach(function(ele) {
            permissoes[permissoes.length] ={text: ele.name, value: ele.id}
        });
        this.permissionsgroupolista = permissoes
        console.log(permissoes);
        this.pagination.totalPages = re.last_page;
        this.pagination.itens = re.total;
    })
    .catch(err => {
        console.log(err)
    })
},
       fetchUser(){
            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            }

            service
            .read(data)
            .then(resp => {
                this.user = resp;
                this.permissoes = resp["permissions_group"]["id"]
                this.user.password = null;
                this.user.permissions = resp.permissions.map(elem => elem.permission_id)
            })
            .catch(err => {
                console.log(err)
            })
       },
       fetchTitles(){
            serviceTitle
            .search()
            .then(resp => {
                resp.forEach(element => {
                    this.titles.push({
                        value: element.id,
                        text: element.name
                    });
                });
            })
            .catch(err => {
                console.log(err)
            })
       },
       fetchPermissions(){
            servicePermission
            .search()
            .then(resp => {
                resp.forEach(element => {
                    this.permissions.push({
                        value: element.id,
                        text: element.name
                    });
                });
            })
            .catch(err => {
                console.log(err)
            })
       },
       saveUserGroupPermission(){
        if(this.user["permissions_group"] != null && this.user["permissions_group"]["id"] != null){
            let information = {
                user_id: this.user["id"],
                user_permissions_groups_id: [this.permissoes]
            }

            let information2 = {
                user_id: this.user["id"],
                user_permissions_groups_id: [this.user["permissions_group"]["id"]]
            }
            service4
                .create(information2)
                .then(resp2 => {



                    service3
                    .create(information)
                    .then(resp => {
                        this.user = resp;
                        this.user.password = null;
                        this.user.permissions = resp.permissions.map(elem => elem.permission_id)
                    })
                    .catch(err => {
                        console.log(err)
                    })
                })
         }
       }
   },
   mounted(){
       this.id = this.$route.params.id;
       this.fetchUser();
       this.fetchTitles();
       this.fetchPermissions();
       this.fetchGroupPermissions()
   }
}
</script>