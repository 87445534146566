import Rest from '@/services/Rest';

/**
 * @typedef {AccessPermissionGroupService}
 */
export default class AccessPermissionGroupService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/v2/user/permissions/group'
}
