import Rest from '@/services/Rest';

/**
 * @typedef {AccessPermissionService}
 */
export default class AccessPermissionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/v2/user/permission'
}
